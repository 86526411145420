<template>
  <div class="wrapper">
    <div>
      <button
        class="back btn btn-sm btn-outline-secondary shadow-none"
        @click="back"
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
    </div>
    <div class="content col-md-12">
      <div class="form-group row" v-for="(item, idx) in newUser" :key="idx">
        <label class="col-md-2 col-form-label text-right">{{
          item.name
        }}</label>
        <div class="col-md-4">
          <input
            type="text"
            class="form-control shadow-none is-disabled"
            :class="
              item.required && item.value.length < 2 ? 'is-invalid' : null
            "
            v-model="item.value"
            v-if="!item.select"
            :autocomplete="false"
          />
          <div v-if="item.select">
            <select
              class="form-control form-control-sm shadow-none"
              v-model="item.value"
            >
              <option
                v-for="(elem, idx) in item.options"
                :key="'m' + idx"
                :value="
                  elem.pseudonym ||
                    elem.cod ||
                    elem.kom ||
                    elem.otchet ||
                    elem.aktiv ||
                    elem.percent
                "
                >{{ elem.name }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label text-right"></label>
        <div class="col-md-4">
          <button
            class="btn btn-sm btn-outline-primary shadow-none"
            @click="add"
            :disabled="disabled"
          >
            создать
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "AddNewUser.vue",
  computed: {
    disabled: function() {
      if (
        this.newUser[5].value.length < 2 ||
        this.newUser[6].value.length < 2
      ) {
        return true;
      } else {
        return false;
      }
    },
    gruppa: function() {
      return this.newUser[1].value;
    }
  },
  watch: {
    gruppa: function() {
      if (this.newUser[1].value && this.newUser[1].value.length < 1) {
        this.newUser[1].value = null;
      }
    }
  },
  data: () => ({
    newUser: [
      { name: "наименование", field: "user", value: "" },
      {
        name: "входит в группу СЦ",
        field: "gruppa_sc",
        value: null
      },
      { name: "имя для отслеживания в ТК", field: "user_name", value: "" },
      {
        name: "менеджер",
        field: "menedger",
        value: "",
        options: null,
        select: true
      },
      {
        name: "доступ на сайт",
        field: "aktiv",
        value: "0",
        options: [
          { name: "разрешен", aktiv: "0" },
          { name: "запрещен", aktiv: "1" }
        ],
        select: true
      },
      { name: "логин", field: "login", value: "", required: true },
      { name: "пароль", field: "password", value: "", required: true },
      {
        name: "регион",
        field: "region",
        value: "",
        options: null,
        select: true
      },
      {
        name: "комиссия",
        field: "komissiya",
        value: "0",
        options: [
          { name: "да", kom: "1" },
          { name: "нет", kom: "0" }
        ],
        select: true
      },
      {
        name: "отчетность",
        field: "otchet",
        value: "да",
        options: [
          { name: "да", otchet: "да" },
          { name: "нет", otchet: "нет" }
        ],
        select: true
      },
      {
        name: "1,5%",
        field: "percent",
        value: 0,
        options: [
          { name: "да", percent: 1 },
          { name: "нет", percent: 0 }
        ],
        select: true
      },
      { name: "email", field: "email", value: "" },
      { name: "№ договора", field: "nomer_dog", value: "" }
    ]
  }),
  mounted() {
    this.getMenedgers();
  },
  methods: {
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    getMenedgers() {
      HTTPGET({
        url: "/admin/users/add_user.php",
        params: {
          get_menedgers: true
        }
      }).then(response => {
        this.newUser[3].options = response.data.menedgers;
        this.newUser[7].options = response.data.regioni;
      });
    },
    add() {
      if (
        this.newUser[5].value.length > 1 &&
        this.newUser[6].value.length > 1
      ) {
        let data = {};
        data.add_new_user = true;
        this.newUser.forEach(element => {
          data[element.field] = element.value;
        });
        HTTPGET({
          url: "/admin/users/add_user.php",
          params: data
        }).then(response =>
          this.$router.push(`/kabinet/users/user/${response.data.id}`)
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  font-size: 0.8em;
  padding: 10px;
}
.content {
  margin-top: 10px;
}
input {
  font-size: 0.8em;
}
.btn-outline-primary {
  font-size: 1.2em;
  width: 100px;
}
</style>
